import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { BasicSEO } from "../components/seo"
import { MHPageProps } from "../types"
import { getExcerpt } from "../excerptHelper"

class BlogPostTemplate extends React.Component<MHPageProps> {
  render() {
    const post = this.props.data.mdx

    return (
      <>
        <BasicSEO
          social={post.social}
          excerpt={getExcerpt(post)}
          ogImage={post.frontmatter.image}
          withPubDate={true}
        />

        <div className="oneColumn  n-type-larger">
          <article className="markdown">
            <MDXRenderer>{post.body}</MDXRenderer>
          </article>
        </div>
      </>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query MarkdownPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      frontmatter {
        title
        description
        robots
      }
      ...MHExcerpt
      ...SocialImage
      ...BasicSocial
    }
  }
`
